.is-premium {
    color: #43b9da !important;
}
.is-premium td {
    color: #FFFFFF !important;
}
.is-premium td:not(:first-child) {
    color: #43b9da !important;
}
.is-premium td:first-child {
    background: #43b9da !important;
}
.is-premium .ant-table-tbody .in-use-row td {
    color: #43b9da !important;
}

.is-limit {
    color: #980000 !important;
}
.is-limit td {
    color: #FFFFFF !important;
}
.is-limit td:not(:first-child) {
    color: #980000 !important;
}
.is-limit td:first-child {
    background: #980000 !important;
}
.ant-table-fixed .ant-table-tbody .is-limit td {
    color: #980000 !important;
}

.is-not-active {
    color: #f17a05 !important;
}
.is-not-active td {
    color: #FFFFFF !important;
}
.is-not-active td:not(:first-child) {
    color: #f17a05 !important;
}
.is-not-active td:first-child {
    background: #f17a05 !important;
}
.ant-table-fixed .ant-table-tbody .is-not-active td {
    color: #f17a05 !important;
}

.is-blocked {
    color: #FF0000 !important;
}
.is-blocked td {
    color: #FFFFFF !important;
}
.is-blocked td:not(:first-child) {
    color: #FF0000 !important;
}
.is-blocked td:first-child {
    background: #FF0000 !important;
}
.ant-table-fixed .ant-table-tbody .is-blocked td {
    color: #FF0000 !important;
}
