/*.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td{*/
/*  border-inline-end: 0;*/
/*}*/

/*react transition group css*/
.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
}

.page-enter-active,
.page-exit-active {
  transition: opacity 200ms;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 300ms;
}

/*====*/

.right-to-left-enter {
  transform: translateX(100%);
}

.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 300ms ease;
}

.right-to-left-exit {
  transform: translateX(0);
}

.right-to-left-exit-active {
  transform: translateX(-100%);
  transition: all 300ms ease;
}

/*====*/

.left-to-right-enter {
  transform: translateX(-100%);
}

.left-to-right-enter-active {
  transform: translateX(0);
  transition: all 300ms ease;
}

.left-to-right-exit {
  transform: translateX(0);
}

.left-to-right-exit-active {
  transform: translateX(100%);
  transition: all 300ms ease;
}

/*===*/

.top-to-bottom-enter {
  transform: translateY(-100%);
}

.top-to-bottom-enter-active {
  transform: translateY(0);
  transition: all 300ms ease;
}

.top-to-bottom-exit {
  transform: translateY(0);
}

.top-to-bottom-exit-active {
  transform: translateY(100%);
  transition: all 300ms ease;
}

/*===*/

.bottom-to-top-enter {
  transform: translateY(100%);
}

.bottom-to-top-enter-active {
  transform: translateY(0);
  transition: all 300ms ease;
}

.bottom-to-top-exit {
  transform: translateY(0);
}

.bottom-to-top-exit-active {
  transform: translateY(-100%);
  transition: all 300ms ease;
}

/*UTILITIES*/

.text-highlight {
  background: -webkit-linear-gradient(45deg, #8e44ad, #3498db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}

.text-start {
  text-align: start;
}

.text-white {
  color: white !important;
}

.text-black {
  color: black !important;
}

.overflow-scroll {
  overflow: auto;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.w-100 {
  width: 100%;
}

.fw-bold {
  font-weight: bold;
}

.fw-bolder {
  font-weight: bolder;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .overflow-scroll {
    overflow: hidden;
  }
}
